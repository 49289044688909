body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Global Style */
*{
    box-sizing: border-box;
}
:root{
    --main-color: #103456;
    --main-background: #D1DFE8;
    --fonts-details: #10345696;
}
html{
    scroll-behavior: smooth;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
}
.container{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.Footer {
    margin-top: auto !important;
}
.DP {
    padding: 40px 0;
    text-align: end;
}

.DP h1{
    margin: 0 0 10px;
    color: #103456;
    color: var(--main-color);
}
.DP .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DP img {
    width: 100%;
    max-width: 450px;
}
.DP .txt {
    margin: 0;
    color: #103456;
    color: var(--main-color);
    line-height: 2;
}
/* small */
@media (min-width: 768px){
    .container{
        width: 750px;
    }
}
/* medium */
@media (min-width: 992px){
    .container{
        width: 970px;
    }
}
/* large */
@media (min-width: 1200px){
    .container{
        width: 1170px;
    }
}
/* Global Style */

/* Header Style */
.Header {
    background-color: #D1DFE8;
    background-color: var(--main-background);
    direction: rtl;
    transition: .3s;
    z-index: 2;
}
.Header-active {
    position: fixed;
    width: 100%;
    transition: .3s;
    top: 0;
}
.Header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header .container .first-element{
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}
.Logo {
    cursor: pointer;
}
.Logo h1{
    color: #103456;
    color: var(--main-color);
    text-shadow: 1px 1px 2px #10345696;
    text-shadow: 1px 1px 2px var(--fonts-details);
}
.Header .container .last-element{
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}
.Menu ul{
    display: flex;
}
.Menu ul li a{
    margin-right: 15px;
    padding: 5px;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    text-shadow: 1px 1px 2px #10345696;
    text-shadow: 1px 1px 2px var(--fonts-details);
}
.LM {
    display: none;
}
.LM .icon{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    cursor: pointer;
}
.LM .icon span {
    width: 20px;
    height: 2px;
    background-color: #FFF;
    transition: .1s;
}
.LM .icon:hover span {
    background-color: #103456;
    background-color: var(--main-color);
}
.LM .menu {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    position: absolute;
    background-color: #103456b8;
    width: 100%;
    left: 0;
    top: 70px;
    transition: .3s;
    z-index: 2;
}
.LM .menu .for-login {
    display: flex;
    flex-direction: column;
}
.LM .menu a,
.LM .menu span {
    color: #FFF;
    padding: 10px 0;
    cursor: pointer;
}
.LM .menu a:hover, 
.LM .menu span:hover {
    border-bottom: 1px solid #FFF;
}
.Profile svg{
    width: 20px;
    fill: #FFF;
}
.Search svg{
    width: 20px;
    fill: #FFF;
}
.Profile svg:hover,
.Search svg:hover path{
    fill: #103456;
    fill: var(--main-color);
}
.UserProfile {
    background-color: #103456;
    background-color: var(--main-color);
    padding: 5px;
    border-radius: 6px;
}
.UserProfile div {
    color: #FFF;
}
.Language .language-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.Language .language-box .language-title {
    display: flex;
    direction: ltr;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}
.Language .language-box .language-title div:first-child {
    color: #FFF;
    font-size: 18px;
    text-shadow: 1px 1px 2px #10345696;
    text-shadow: 1px 1px 2px var(--fonts-details);
}
.Language .language-box .language-title div:last-child{
    border-width: 6px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    margin-top: 10px;
}
.Language .language-box .language-list{
    display: flex;
    flex-direction: row;
    background-color: #FFF;
    position: absolute;
    bottom: -35px;
    box-shadow: 3px 3px 3px -3px;
}
.Language .language-box .language-list span{
    color: #103456;
    color: var(--main-color);
    padding: 5px 10px ;
    cursor: pointer;
    transition: .3s;
}
.Language .language-box .language-list span:hover{
    color: #FFF;
    background-color: #103456;
    background-color: var(--main-color);
}
.Language .box div:hover{
    background-color: #103456;
    background-color: var(--main-color);
    border: 1px solid #103456;
    border: 1px solid var(--main-color);
}
/* Header Style */

/* Home Style */
/* ---------- BOH ---------- */
.Home .background-of-home {
    background-color: #D1DFE8;
    background-color: var(--main-background);
}
.Home .background-of-home .container  {
    display: flex;
    align-items: center;
}
.BackgroundImage {
    flex-basis: 60%;
}
.BackgroundTitle {
    flex-basis: 40%;
}
.BackgroundImage img{
    width: 1000px;
    max-width: 100%;
}
.BackgroundTitle h1{
    font-size: 50px;
    color: #103456;
    color: var(--main-color);
    margin: 0;
    margin-left: 15px;
    text-shadow: 1px 1px 2px #10345696;
    text-shadow: 1px 1px 2px var(--fonts-details);
}
/* ---------- BOH ---------- */

/* ---------- SFR ---------- */
.SFR h1 {
    color: #103456;
    color: var(--main-color);
}
.SFR .type{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
}
.SFR .type div {
    flex-basis: 50%;
    padding: 10px 0;
    color: #103456;
    color: var(--main-color);
    cursor: pointer;
}
.SFR form div {
    margin-top: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    grid-gap: 20px;
    gap: 20px;
    direction: rtl;
}
.SFR form div input {
    max-width: 300px;
    outline: none;
    font-size: 15px;
}
.SFR form select {
    width: 100px;
    background-color: transparent;
}
.SFR form div input,
.SFR form select {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #AEBCC6;
    color: gray;
}
.SFR form div input:last-of-type {
    width: 250px;
}
.SFR form .submit {
    margin-top: 40px;
    width: 75px;
    padding: 11px 0;
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
    background-color: #103456;
    background-color: var(--main-color);
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    transition: .1s;
}
.SFR form .submit:active {
    background-color: #FFF;
    color: #103456;
    color: var(--main-color);
    margin-top: 43px;
}
.SearchPage {
    margin-top: 100px;
}
/* ---------- SFR ---------- */

/* ---------- SOS ---------- */
.SOS {
    margin: 100px 0 0 0;
}
.SOS .move {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    grid-gap: 20px;
    gap: 20px;
    direction: ltr !important;
}
.SOS .move svg{
    width: 30px;
    fill: #103456;
    fill: var(--main-color);
    cursor: pointer;
}
.SOS h1 {
    color: #103456;
    color: var(--main-color);
    margin: 0 0 50px;
}
.card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.swiper-slide {
    height: auto !important;
}
.card .img {
    overflow: hidden;
}
.card div img{
    max-width: 100%;
}
.card .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 2 1;
    justify-content: space-between;
}
.card .content .txt {
    text-align: right;
}
.card .content .txt span {
    color: #777;
    font-size: 14px;
    font-weight: bold;
}
.card .content .txt h3 {
    margin: 5px 0 0;
    color: #103456;
    color: var(--main-color);
}
.card .content .txt p {
    margin: 15px 0 0;
    color: #10345696;
}
.card .content .details {
    display: flex;
    align-items: flex-end;
    direction: rtl;
    margin-top: 20px;
    justify-content: space-between;
}
.card .content .details .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    grid-gap: 10px;
    gap: 10px;
}
.card .content .details .txt h4 {
    margin: 0 0 5px;
    color: #103456;
    color: var(--main-color);
}
.card .content .details .txt div div{
    display: flex;
    align-items: center;
    direction: rtl;
}
.card .content .details .txt div div svg{
    width: 17px;
    fill: #103456;
    fill: var(--main-color);
    margin-left: 5px;
}
.card .content .details .txt div p {
    margin: 0;
    font-size: 14px;
}
.card .content .details button {
    padding: 5px;
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
    color: #FFF;
    background-color: #103456;
    background-color: var(--main-color);
    cursor: pointer;
    display: flex;
}
.card .content .details button:active {
    background-color: #FFF;
    color: #103456;
    color: var(--main-color);
}

/* Swiper Style */
.swiper-pagination{
    position: relative !important;
    bottom: 0 !important;
    width: -webkit-max-content !important;
    width: max-content !important;
}
.swiper-pagination-bullet {
    width: 30px !important;
    height: 4px !important;
    border-radius: 0 !important;
}
.swiper-pagination-bullet-active {
    background-color: #103456 !important;
    background-color: var(--main-color) !important;
}
/* Swiper Style */
/* ---------- SOS ---------- */

/* ---------- NA ---------- */
.NA {
    margin: 100px 0;
}
.NA h1 {
    color: #103456;
    color: var(--main-color);
    margin: 0 0 50px;
}
.NA .box{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    align-items: stretch;
}
.NA .box .card {
    max-width: 360px;
    display: flex;
    flex-direction: column;
}
.NA button {
    margin-top: 50px;
    padding: 10px;
    background-color: #103456;
    background-color: var(--main-color);
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
}
.NA button:active {
    background-color: #FFF;
    color: #103456;
    color: var(--main-color);
}
/* ---------- NA ---------- */

/* ---------- AU ---------- */
.about-us {
    margin-top: 100px;
    background-color: #D1DFE8;
    background-color: var(--main-background);
}
.AU {
    padding: 40px 0;
    text-align: end;
}
.AU h1 {
    margin: 0 0 10px;
    color: #103456;
    color: var(--main-color);
}
.AU .box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.AU .box img {
    max-width: 450px;
}
.AU .box p{
    margin: 0;
    color: #103456;
    color: var(--main-color);
    line-height: 2;
}
/* ---------- AU ---------- */

/* ---------- OCO ---------- */
.our-customers-opinions {
    margin-top: 100px;
    margin-bottom: 100px;
}
.OCO h1 {
    color: #103456;
    color: var(--main-color);
}
.OCO .box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    margin-top: 50px;
}
.OCO .box .card {
    position: relative;
    padding: 10px;
    text-align: end;
    margin-top: 40px;
}
.OCO .box .card img {
    max-width: 80px;
    border-radius: 50px;
    position: absolute;
    right: 5px;
    top: -50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.OCO .box .card .txt {
    padding: 35px 0 10px;
}
.OCO .box .card .txt h3 {
    color: #103456;
    color: var(--main-color);
    margin: 0 0 15px;
}
.OCO .box .card .txt p {
    color: #777;
    font-size: 15px;
    line-height: 1.7;
    margin: 0;
}
/* ---------- OCO ---------- */
/* Home Style */

/* Footer Style */
.Footer {
    background-color: #103456;
    background-color: var(--main-color);
    direction: rtl;
    margin-top: 100px;
}
.Footer .container {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    align-items: baseline;
    padding-top: 25px;
    padding-bottom: 25px;
    gap: 10px; */
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: baseline;
    justify-content: space-between;
    grid-row-gap: 20px;
    row-gap: 20px;
}
.Footer .container div {
    text-align: start;
}
.Footer .Logo h1 {
    color: #FFF;
    margin: 0 0 15px;
    font-size: 40px;
}
.SM {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.SM div svg{
    width: 15px;
}
.SM img{
    width: 15px;
}
.SM div svg path {
    fill: #FFF !important;
}
.IL {
    text-align: start;
}
.IL h3 {
    color: #FFF;
    margin: 0 0 15px;
    font-weight: 600;
}
.IL .links {
    display: flex;
    max-width: 250px;
    flex-wrap: wrap;
    grid-column-gap: 45px;
    -webkit-column-gap: 45px;
            column-gap: 45px;
    grid-row-gap: 25px;
    row-gap: 25px;
}
.IL .links a {
    color: #D1DFE8;
}
.CU {
    text-align: end;
}
.CU h3 {
    margin: 0 0 15px;
    color: #FFF;
    font-weight: 600;
}
.CU .box {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
.CU .box div {
    color: #D1DFE8;
}
.location {
    text-align: start;
}
.location h3 {
    margin: 0 0 15px;
    color: #FFF;
    font-weight: 600;
}
.location .box div {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.location .box div div {
    color: #D1DFE8;
}
.location .box div svg {
    width: 20px;
}
.location .box div svg path {
    fill: #D1DFE8;
}
/* Footer Style */

/* Login Style */
.Login,
.SignIn {
    position: fixed;
    width: 50%;
    left: 50%;
    top: 50%;
    background-color: #FFF;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: .3s;
    z-index: 5;
    padding: 20px 0;
}
.X {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 20px;
    top: 30px;
    cursor: pointer;
}
.X svg path {
    fill: #103456;
    fill: var(--main-color);
}
.Login h1,
.SignIn h1 {
    color: #103456;
    color: var(--main-color);
    margin: 0;
}
.Login form,
.SignIn form {
    margin-top: 20px;
}
.Login form div,
.SignIn form div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}
.Login form div input,
.SignIn form div input {
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #AEBCC6;
    color: gray;
    outline: none;
    margin-top: 10px;
    max-width: 100%;
}
.error {
    font-size: 12px;
    margin-top: 10px;
    color: red;
}
.opacity {
    position: fixed;
    background-color: black;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 2;
    opacity: 0.5;
    transition: .3s;
}
.Login button,
.SignIn button {
    margin-top: 20px;
    width: 70px;
    padding: 11px 0;
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
    background-color: #103456;
    background-color: var(--main-color);
    color: #FFF;
    font-size: 14px;
    cursor: pointer;
    border-radius: 6px;
    transition: .1s;
}
.Login button:active,
.SignIn button:active {
    background-color: #FFF;
    color: #103456;
    color: var(--main-color);
}
.acount {
    display: block;
    margin-top: 10px;
}
.acount div {
    color: #96a7b7;
}
.acount a {
    display: block;
    margin-top: 5px;
    color: #103456;
    color: var(--main-color);
}
/* Login Style */

/*  Rent && Sale Pages Style  */
.Show-Pages {
    margin: 100px 0;
}
.Show-Pages h1 {
    color: #103456;
    color: var(--main-color);
    margin: 0 0 50px;
}
.Show-Pages .box{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    justify-items: center;
}
.Show-Pages .box .card {
    max-width: 360px;
    display: flex;
    flex-direction: column;
}
.Show-Pages button {
    margin-top: 50px;
    padding: 10px;
    background-color: #103456;
    background-color: var(--main-color);
    border: 2px solid #103456;
    border: 2px solid var(--main-color);
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
}
.Show-Pages button:active {
    background-color: #FFF;
    color: #103456;
    color: var(--main-color);
}
/* Rent && Sale Pages Style  */

/* Details Pages Style */
.Details {
    margin: 50px 0;
}
.Details .box {
    text-align: start;
    direction: rtl;
}
.Details .box .imgs {
    display: flex;
    direction: rtl;
    grid-gap: 20px;
    gap: 20px;
}
.Details .imgs .big-imgs img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 1px 5px 6px black;
}
.box .imgs .little-imgs {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
} 
.box .imgs .little-imgs img {
    max-width: 250px;
    border-radius: 10px;
    box-shadow: 1px 5px 6px black;
}
.Details .box .title h2 {
    color: #103456;
    color: var(--main-color);
}
.Details .box .txt {
    display: flex;
    align-items: baseline;
    grid-gap: 50px;
    gap: 50px;
    margin-top: 25px;
}
.Details .box .txt .box-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    grid-gap: 20px;
    gap: 20px;
    flex-basis: 50%;
}
.box .types {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.box .types div {
    background-color: #103456;
    background-color: var(--main-color);
    padding: 10px 5px;
    color: #FFF;
    border-radius: 6px;
}
.location-and-space {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.Details h3 {
    color: #103456;
    color: var(--main-color);
    margin: 0 0 15px;
}
.location-and-space .details {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    color: #10345696;
    color: var(--fonts-details);
}
.location-and-space svg {
    width: 20px;
    fill: #103456;
    fill: var(--main-color);
}
.details-of-item div {
    color: #10345696;
    color: var(--fonts-details);
    line-height: 1.5;
}
.the-facilities .facilities-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    max-height: 200px;
}
.the-facilities .facilities-box div {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.the-facilities .facilities-box div img {
    max-width: 20px;
}
.the-facilities .facilities-box div span {
    color: #10345696;
    color: var(--fonts-details);
}
.location-in-map img {
    max-width: 40%;
    border-radius: 20px;
    border: 1px solid #D1DFE8;
    border: 1px solid var(--main-background);
}
/* Details Pages Style */

/* Loading Components Style */
.REL {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #D1DFE8;
    background-color: var(--main-background);
}
.REL .svg {
    max-width: 500px;
    max-width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
/* Loading Components Style */

/* Privacy Policy Style */
.PP .container .txt h1 {
    color: #103456;
    color: var(--main-color);
    text-align: end;
}
.PP .container .box{
    display: flex;
    align-items: center;
}
.PP .container div {
    flex-basis: 50%;
}
.PP img {
    max-width: 100%;
}
.PP .txt div {
    color: #103456;
    color: var(--main-color);
    line-height: 2;
    text-align: end;
}
/* Privacy Policy Style */

/* Error Page Style */
.error-page .container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.error-page svg {
    max-width: 500px;
}
.error-page h1 {
    color: #103456;
    color: var(--main-color);
    font-size: 40px;
    margin-top: 0;
}
.error-page .error-txt {
    color: #AEBCC6;
}
.error-page button {
    margin-top: 15px;
    padding: 10px 5px;
    color: #FFF;
    font-size: 15px;
    background-color: #103456;
    background-color: var(--main-color);
    border: none;
    cursor: pointer;
    border-radius: 6px;
}
/* Error Page Style */

/* Scroll Style */

/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #D1DFE8;
    background: var(--main-background);
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #103456;
    background: var(--main-color);
    border-radius: 6px;
}
/* Scroll Style */

/* ---------- Media ---------- */

@media (max-width: 991px) {
    .LM {
        display: flex;
    }
    .Menu ul{
        display: none;
    }
    .AU .box img {
        max-width: 300px;
    }
    .AU .box p {
        font-size: 14px;
    }
    .box .imgs {
        flex-direction: column;
    }
    .box .imgs .little-imgs {
        flex-direction: row;
    }
}

@media (max-width: 767px) {
    .BackgroundTitle h1 {
        font-size: 35px;
    }
    .card .content .txt span {
        font-size: 10px;
    }
    .card .content .txt h3 {
        font-size: 15px;
    }
    .card .content .txt p {
        font-size: 12px;
    }
    .card .content .details .txt h4 {
        font-size: 12px;
    }
    .card .content .details .txt div p {
        font-size: 10px;
    }
    .card .content .details .txt div div svg {
        width: 13px;
    }
    .Profile svg {
        display: none;
    }
    .Search svg {
        display: none;
    }
    .Header .container .last-element {
        grid-gap: 10px;
        gap: 10px;
    }
    .SFR form div {
        flex-direction: column;
    }
    .SFR form div input,
    .SFR form select {
        outline: none;
    }
    .AU .box img {
        display: none;
    }
    .Login,
    .SignIn {
        width: 100%;
    }
    .Login h1,
    .SignIn h1 {
        font-size: 25px !important;
    }
    .X {
        width: 10px;
        height: 10px;
        right: 15px;
        top: 10px;
    }
    .box .imgs .little-imgs {
        flex-direction: column;
    }
    .box .imgs .little-imgs img{
        max-width: 100%;
    }
    .Sale .box .txt {
        flex-direction: column;
    }
    .PP img {
        display: none;
    }
    .PP .container .box div {
        flex-basis: auto;
    }
    .DP img {
        display: none;
    }
    .DP .box {
        display: block;
    }
}

@media (max-width: 500px) {
    .BackgroundTitle h1 {
        display: none;
    }
    .BackgroundImage {
        flex-basis: auto;
    }
    .PP .txt div {
        font-size: 14px;
    }
    .Show-Pages .box,
    .NA .box {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .swiper-pagination-bullet {
        width: 15px !important;
    }
    .OCO .box {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
@media (max-width: 450px) {
    .Footer .container {
        flex-direction: column;
        align-items: center;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
    .IL .links {
        flex-direction: column;
    }
    .location .box div {
        flex-direction: column;
        align-items: center;
    }
    .location h3{
        display: none;
    }
}

/* ---------- Media ---------- */


